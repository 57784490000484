import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import './Contactspage.scss'

const Contactspage = () => {
  const form = useRef();
  const [showPopup, setShowPopup] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_46ycw2w",
        "template_he113vr",
        form.current,
        "uFlAaPMurQlUFfn7n"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
          setShowPopup(true); // Set state to show the popup
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const resetForm = () => {
    form.current.reset(); // Reset form fields
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Hide the popup
    resetForm(); // Reset the form fields
  };

  return (
    <div className="maincontactdiv">
      <div className="contact-details">
        <h2>Get in Touch with Us</h2>
        <p>We'd love to hear from you! Whether you have a question, 
          feedback, or a project in mind, don't hesitate to reach out. We're always happy to connect
        </p>
        <p>Let us create an impact together. Every person deserves the right to education, 
          clean water, and health care. Our projects support communities to prosper in this regard. Talk to us and donate.</p>
        <ul>
          <li>
            <span className="icon"></span>
            <span>info@mbuanene.com</span>
          </li>
          <li>
            <span className="icon"></span>
            <span><b>+254706757140</b></span>
          </li>
          <li>
            <span className="icon"></span>
            <span>Kyamatu Shopping Center, Kitui County  <br />
              P.O. Box 13-90213, Zombe, Kitui
            </span>
          </li>
        </ul>
        <div className="social-media">
          <a href="#">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="#">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="#">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Message</label>
        <textarea name="message" />
        <input type="submit" value="Send" />
      </form>

      {/* Popup */}
      {showPopup && (
        <div className="popup">
          <p>Message sent successfully!</p>
          <button onClick={handleClosePopup}>Close</button>
        </div>
      )}
    </div>
  );
};

export default Contactspage;
