import React, { useRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "../../Assets/logos.png";
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navigation() {
  const navbarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        const navbarToggler = document.getElementById('navbartogler');
        if (navbarToggler && !navbarToggler.contains(event.target)) {
          const navbarCollapse = document.getElementById('navbarScroll');
          if (navbarCollapse.classList.contains('show')) {
            navbarToggler.click();
          }
        }
      }
    }

    // Attach event listener
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Function to close navbar when link is clicked
  function handleLinkClick() {
    const navbarToggler = document.getElementById('navbartogler');
    if (navbarToggler) {
      navbarToggler.click();
    }
  }

  return (
    <Navbar bg="lightwhoweare" expand="lg" fixed='top' className='navigation' id='Navigation' fluid ref={navbarRef}>
      <Container className='navitem1' >
        <Navbar.Brand href="#home" className='logodiv'>
          <img
            src={logo}
            width="100"
            height="30"
            className="logo"
            alt="React Bootstrap logo"
          />
          <b className='logotxt'>MBUANENE INITIATIVE</b>
        </Navbar.Brand>
      </Container>
      <Container  className='navitem2'>
        <Navbar.Toggle aria-controls="navbarScroll" id='navbartogler' />
        <Navbar.Collapse id="navbarScroll" className='collapse'>
          <Nav
            className="me-auto my-2 my-lg-0 uls links"
            style={{ maxHeight: '100px' }}
          >
            <Link   className='x'  to='/' onClick={handleLinkClick}>Home</Link>
            <Link   className='x'  to='/Aboutpage' onClick={handleLinkClick}>About Us</Link>
            <Link   className='x'  to='/Servicespage' onClick={handleLinkClick}>Programs</Link>
            <Link   className='x'  to='/Contactspage' onClick={handleLinkClick}>Contact Us</Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
